<template>
    <div class = "project-item">
        <div class="card">
            <div class="card-header ">
                <p class="card-header-title has-background-info has-text-white">
                    {{title}}
                </p>
            </div>
            <div class="card-image" v-if="hasImg">
                <figure class="image is-square">
                    <img :src="img">
                </figure>
            </div>
            <div class="card-content">
                <div class="content">
                    <p>{{description}}</p>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="content">
                            <p>Languages:</p>
                            <ul>
                                <li 
                                v-for="(item,index) in languages"
                                :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="column">
                        <div class="content">
                            <p>Technologies:</p>
                            <ul>
                                <li 
                                v-for="(item,index) in technologies"
                                :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <a :href="link" class="card-footer-item">Visit</a>
                <a class="card-footer-item"
                v-for="(item,index) in git"
                :key="index"
                :href="item.link">
                {{item.name}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'ProjectsItem',
    props:{
        title:String,
        img:String,
        description:String,
        languages:Array,
        technologies:Array,
        git:Array,
        link:String,
        hasRepo:Boolean,
        hasImg:Boolean
    },
    data(){
        return{
            
        }
    }
}
</script>