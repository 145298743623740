<template>
    <div class="projects">
        <div class="box">
            <div class="columns">
                <div class="column"
                v-for="(project,index) in projects"
                :key="index">
                    <project-item
                    :="project"
                    ></project-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import projects_data from '../assets/projects'
import ProjectsItem from '../components/ProjectsItem.vue'
export default {
    name:'Projects',
    components:{
        'project-item':ProjectsItem,
    },
    data(){
        return{
            projects:projects_data
        }
    },
    created(){
        document.title = "Projects";
    }
}
</script>